import PropTypes from "prop-types";
import React from "react";

// images
import Logo from '../images/the_300FeetOut_Logo.inline.svg';


import Header from "./header";
function Layout({ children }) {
  return (
    <React.Fragment>
      <div className="bg-second_color">
        <div className="flex flex-col w-full md:max-w-screen-xl min-h-screen font-second_font font-light 
          text-2xl text-third_color md:pl-12 md:pr-10 mx-auto bg-second_color">
          <div className="flex flex-col md:flex-row">
            <Header />

            <main className="flex flex-col flex-1 mb-16 px-5 md:px-10 pb-8 md:w-1/2" id="main">
              {children}
            </main>
          </div>
        </div>
      </div>
      <footer className="flex items-center bg-footer_color font-second_font text-black text-center mx-auto w-full h-64">
        <nav className="flex flex-col w-full md:w-1/2 mx-auto p-4 md:p-8 text-sm">
          <div className="mx-auto">
            <a href="https://www.300feetout.com" className="block no-underline">
              <Logo className="w-full" />
            </a>
          </div>
          <p className="uppercase mt-6">
            Questions? Feel free to ping us for answers!
          </p>
          <p className="uppercase">
            <a href="mailto:hello@300feetout.com" className="no-underline hover:text-primary_color transition-colors duration-300" >
              hello@300feetout.com
            </a>
          </p>
        </nav>
      </footer>
    </React.Fragment>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
